import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { NexusFooter } from 'Components/NexusNavigation/NexusFooter';
import { NexusLayout } from 'Layouts/NexusLayout';
import { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import SimpleBar from 'simplebar-react';
import { NexusPageData } from 'src/lib/pageDataLoading/pageDataLoading';
import { nexusActiveAndOpen } from '../../../src/utils/nexusActiveAndOpen';
import { GetServerSidePropsWithCustomPageDataContext } from 'Hocs/withDemiplaneSSR/withDemiplaneSSR.types';
import { withDemiplaneSSR } from 'Hocs/withDemiplaneSSR';
import { dynamicImport } from 'src/lib/dynamicImport';
import { useEffect } from 'react';
import { addPageStyles } from 'src/lib/addPageStyles';
import { removePageStyles } from 'src/lib/removePageStyles';

const NexusAlerts = dynamicImport(
  'NexusAlerts',
  () => import('Components/NexusAlerts').then((mod) => mod.NexusAlerts),
  { ssr: false }
);

const IframeResizer = dynamicImport(
  'IframeResizer',
  () => import('iframe-resizer-react'),
  {
    ssr: false,
  }
);

const ParseHtml = dynamicImport(
  'ParseHtml',
  () => import('Components/ParseHtml').then((mod) => mod.ParseHtml),
  {
    ssr: false,
  }
);

interface INexusProps extends NexusPageData {
  metaTitle: string;
  metaDescription: string;
  metaImg: string;
  finalHtml?: string;
}

const useStyles = makeStyles(() => ({
  scrollableContainer: {
    maxHeight: '100%',
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .simplebar-content': {
      height: '100%',
    },

    '& body': {
      height: '100%',
    },

    '& html': {
      height: '100%',
    },

    '& .simplebar-offset': {
      maxWidth: '100dvw',
    },
  },
  '@global': {
    '@media screen and (max-width: 767px)': {
      '.ctacontainer': {
        marginBottom: '0 !important',
      },
    },

    '@media screen and (max-width: 991px)': {
      '.ctacontainer': {
        marginBottom: '0 !important',
      },
    },

    '@media screen and (max-width: 479px)': {
      '.ctasection': {
        marginBottom: '0 !important',
      },
    },
  },
}));

const Nexus = ({
  nexus,
  metaTitle,
  metaDescription,
  metaImg,
  finalHtml,
}: INexusProps) => {
  const fullHeader = useMediaQuery('(min-width:1024px)');
  const classes = useStyles({ nexusHeaderVisible: fullHeader });
  const router = useRouter();
  const theme = useTheme();
  const { nexusSlug } = router.query;

  useEffect(() => {
    addPageStyles([
      `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/navigation.css`,
      `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/${nexus?.slug}/nexus.css`,
    ]);

    return () => {
      removePageStyles([
        `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/navigation.css`,
        `${process.env.NEXT_PUBLIC_STYLES_CDN_URL}/${nexus?.slug}/nexus.css`,
      ]);
    };
  }, [nexus?.slug]);

  if (!nexus || nexus.nexus_landings.length <= 0) {
    return <div>No content for this nexus landing page</div>;
  }

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        canonical={`${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}/nexus/${nexusSlug}`}
        openGraph={{
          url: '/',
          title: metaTitle,
          description: metaDescription,
          type: 'website',
          images: [
            {
              url: metaImg,
              alt: metaTitle,
            },
          ],
          site_name: 'Demiplane',
        }}
        twitter={{
          handle: '@DemiplaneRPG',
          cardType: 'summary_large_image',
        }}
      />
      <NexusAlerts
        alerts={[
          { alertType: 'PROMOTION', trackDismiss: true },
          { alertType: 'PAYMENT' },
        ]}
      />
      {!!nexus.nexus_landings[0].url ? (
        <Box>
          <IframeResizer
            src={nexus.nexus_landings[0].url}
            style={{
              width: '1px',
              minWidth: '100dvw',
              border: 'none',
              display: 'block',
              minHeight: 'calc(100dvh - 80px)',
              [theme.breakpoints.down(1366)]: {
                minHeight: 'calc(100dvh - 72px)',
              },
            }}
            heightCalculationMethod='bodyOffset'
          />
          <NexusFooter />
        </Box>
      ) : (
        <SimpleBar className={classes.scrollableContainer}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100%',
              height: '100%',
            }}
          >
            <ParseHtml
              html={finalHtml ?? ''}
              nexusId={nexus.id}
              nexusSlug={nexusSlug as string}
            />
            <NexusFooter />
          </Box>
        </SimpleBar>
      )}
    </>
  );
};

export const getServerSideProps = withDemiplaneSSR(
  async (ctx: GetServerSidePropsWithCustomPageDataContext) => {
    // const { parse } = await import('node-html-parser');
    const { params, customPageData, authResult } = ctx;

    const nexusPageData = customPageData as NexusPageData;

    if (!params?.nexusSlug) {
      return {
        redirect: {
          destination: '/home',
          permanent: false,
        },
      };
    }

    const props: INexusProps = {
      ...nexusPageData,
      finalHtml: '',
      metaTitle: 'Demiplane',
      metaDescription:
        'Find Your Next Adventure On Demiplane - Matchmaking, Video, Voice, Shared Journaling, And Much More To Come!',
      metaImg:
        'https://demiplane-static.s3-us-west-2.amazonaws.com/DemiplaneOG.jpg',
    };

    if (
      !!nexusPageData.nexus?.nexus_landings[0]?.html &&
      nexusPageData.nexus?.nexus_landings[0]?.html.length > 0
    ) {
      props.finalHtml = nexusPageData.nexus?.nexus_landings[0].html ?? '';
      //   const htmlElements = parse(
      //     nexusPageData.nexus?.nexus_landings[0].html ?? ''
      //   );

      //   const headStyleElements = htmlElements
      //     .querySelector('head')
      //     ?.querySelectorAll('style')
      //     .map((style) => style.toString());

      //   const headsSriptElements = htmlElements
      //     .querySelector('head')
      //     ?.querySelectorAll('script')
      //     .filter((script) => {
      //       return !script.innerText.startsWith('WebFont.load');
      //     })
      //     .map((script) => script.toString());

      //   const bodyElement = htmlElements.querySelector('body');

      //   const bodyClass = bodyElement?.getAttribute('class');

      //   const bodyElements = bodyElement?.childNodes.map((child) =>
      //     child.toString()
      //   );

      //   props.finalHtml = `
      // <div class="${bodyClass}">
      // ${headStyleElements?.join('')}
      // ${headsSriptElements?.join('')}
      // ${bodyElements?.join('')}
      // </div>`;
    }

    if (!!nexusPageData.nexus) {
      props.metaTitle = `${
        nexusPageData.nexus.name ?? ''
      } Nexus - Digital TTRPG Toolset`;
      props.metaDescription = `The Official Digital Toolset for ${
        nexusPageData.nexus.name ?? ''
      } on Demiplane`;
      props.metaImg = `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}/nexus/${nexusPageData.nexus.asset_slug}/og-image.png`;

      if (nexusPageData.nexus.nexus_landings.length > 0) {
        const landing = nexusPageData.nexus.nexus_landings[0];

        if (!!landing.og_title) {
          props.metaTitle = landing.og_title;
        }

        if (!!landing.og_description) {
          props.metaDescription = landing.og_description;
        }

        if (!!landing.og_image) {
          props.metaImg = landing.og_image;
        }
      }

      if (!!props.nexus) {
        const nexusActive = await nexusActiveAndOpen(props.nexus, authResult);

        if (!nexusActive) {
          console.log(`Nexus Not Active`);

          return {
            redirect: {
              destination: '/home',
              permanent: false,
            },
          };
        }
      }
    } else {
      console.log(`No Nexus Found`);
      return {
        redirect: {
          destination: '/home',
          permanent: false,
        },
      };
    }

    return { props };
  },
  { nexus: true, auth: { enabled: true } }
);

Nexus.getLayout = (page: NextPage, pageProps: INexusProps) => {
  return (
    <NexusLayout pageProps={pageProps} {...pageProps}>
      {page}
    </NexusLayout>
  );
};

export default Nexus;
